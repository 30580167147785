import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'

const SectionWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 20px;

  .content-section {
    flex: 1;
    min-width: 300px;
    padding-right: 20px;
    text-align: left; /* Ensure all content is left-aligned by default */

    .image-container {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
        border: none;
      }
    }

    .description {
      color: #111;
      font-size: 1rem;
      line-height: 1.5;
      padding: 20px 0; /* Removed horizontal padding for better left alignment */
      margin-bottom: 30px;
      text-align: left;

      p {
        margin: 0;
        padding: 0;
        @media (min-width: 1650px) {
          font-size: 1.3rem;
        }
        @media (max-width: 1600px) {
          font-size: 1.2rem;
        }
      }
      @media (max-width: 1090px) {
        font-size: 0.9rem;
      }
    }

    .description-heading {
      font-size: 2.4rem;
      color: #047cc2;
      margin: 0 0 20px 0;
      padding: 0;
      text-align: left;
      @media (max-width: 1090px) {
        font-size: 1.5rem;
      }
    }

    /* Updated cards styling */
    .impact-cards-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 30px 0 0 0;
      padding: 0;
      text-align: left;
    }

    .impact-card {
      padding: 0;
      margin: 0;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '•';
        color: #047cc2;
        font-size: 1.5rem;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .card-content {
      color: #333;
      font-size: 1.2rem;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      display: inline-block;
    }
  }

  /* Rest of your existing styles... */
  .form-section {
    /* ... existing form section styles ... */
  }

  .thank-you-section {
    /* ... existing thank you section styles ... */
  }

  .Gartner-description {
    margin-top: 100px;
    background-color: #102c64;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23035484' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23047CC2'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    color: #fff;
    font-size: 1rem;
    padding: 70px 20px;
    border-radius: 28px;
    text-align: center;

    p {
      line-height: 34px;
      margin: 0;
      padding: 0;
      @media (min-width: 1650px) {
        font-size: 1.3rem;
      }
      @media (max-width: 1600px) {
        font-size: 1.2rem;
      }
    }
    @media (max-width: 1090px) {
      font-size: 0.9rem;
    }
  }
`

export const AppGartnerReprintGenAIRest = () => {
  return (
    <SectionWrapper>
      <div className="content-section">
        <div className="description">
          <ReUseHOneTag
            Heading="Generative AI's Impact on Procurement: A Gartner Exploration"
            HeadingStyle="description-heading"
          />
          <p>
            Gartner's research delves into how Generative AI will transform procurement and the potential challenges
            that Chief Procurement Officers (CPOs) may encounter in the future. Raindrop, with its AI-enabled Source to
            Pay platform, has been at the forefront of this AI revolution from its inception, after all - it's even in
            our name
          </p>
        </div>
        <ReUseHOneTag Heading="Read about the impact, including how:" HeadingStyle="description-heading" />
        <div className="impact-cards-container">
          <div className="impact-card">
            <p className="card-content">Agentic reasoning will enable autonomous decision making</p>
          </div>

          <div className="impact-card">
            <p className="card-content">Multimodality will make AI more intuitively consumable</p>
          </div>

          <div className="impact-card">
            <p className="card-content">
              AI agents in procurement will emerge to shift the role of people in the procurement process
            </p>
          </div>
        </div>
      </div>
      <div className="Gartner-description">
        <p>
          Gartner's research delves into how Generative AI will transform procurement and the potential challenges that
          Chief Procurement Officers (CPOs) may encounter in the future. Raindrop, with its AI-enabled Source to Pay
          platform, has been at the forefront of this AI revolution from its inception, after all - it's even in our
          name!
        </p>
      </div>
    </SectionWrapper>
  )
}
