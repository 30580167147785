import React from 'react'
import styled from 'styled-components'

const AppGartnerReprintGenAIBannerWapper = styled.div`
  .BannerContainer {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;

    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }

  .ContentSection {
    max-width: 55%;

    @media (max-width: 1090px) {
      max-width: 100%;
    }
  }

  .Heading {
    font-size: 2.5rem;
    line-height: 1.3;
    margin-bottom: 20px;
    color: #047cc2;
    padding: 0px 0px 12px 0px;

    @media (max-width: 1090px) {
      font-size: 2rem;
    }
  }

  .Paragraph {
    font-size: 1.2rem;
    color: #111;
    margin-bottom: 30px;
    padding: 0px 0px 12px 0px;

    @media (max-width: 1090px) {
      font-size: 1rem;
    }
  }

  .ImageSection {
    max-width: 40%;

    @media (max-width: 1090px) {
      max-width: 100%;
      margin-top: 30px;
    }
  }

  .BannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }

  .DownloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #34a1d5;
    color: white;
    border: none;
    border-radius: 42px;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #fff;
      color: #111;
      box-shadow: 0 0 0 2px #34a1d5;
    }

    @media (min-width: 1650px) {
      font-size: 1.2rem;
      padding: 15px 30px;
    }

    @media (max-width: 425px) {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
`

export const AppGartnerReprintGenAIBanner = () => {
  return (
    <AppGartnerReprintGenAIBannerWapper>
      <div className="BannerContainer">
        <div className="ContentSection">
          <h2 className="Heading">
            Gartner Analyst Research - How Generative AI Progress Will Shape the Future of Procurement
          </h2>
          <p className="Paragraph">
            With new model releases and additional functionality, generative AI is progressing rapidly.
          </p>
          <button
            className="DownloadButton"
            onClick={() => {
              window.open('https://www.gartner.com/en', '_blank')
            }}
          >
            Download Now
          </button>
        </div>
        <div className="ImageSection">
          <img
            className="BannerImage"
            src="https://storage.googleapis.com/raindroppublic/website_data/Gartner-Reprint-Gen-AI-Paper-resources-card-img.jpg"
            alt="Gartner-Reprint-Gen-AI-Reprint-Image"
          />
        </div>
      </div>
    </AppGartnerReprintGenAIBannerWapper>
  )
}
